$primaryColor: #DBAA55 !default;
$primaryLightColor: #d3b073 !default;
$primaryDarkColor: #daa346 !default;
$primaryDarkerColor: #df9920 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import '../_variables';
@import './_fonts';
@import '../../../../theme-base/_components';
@import '../_extensions';
